/* eslint-disable */
import { FFmpeg, createFFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import axios from "axios";

var ffmpeg = null;
var isConverting = false;
var conversionQueue = [];
var conversionCounter = 0;

async function Init(){
    ffmpeg = createFFmpeg({
        log: false,
        corePath: '/ffmpeg/ffmpeg-core.js',
        wasmPath: '/ffmpeg/ffmpeg-core.wasm',
    });
    // ffmpeg.setProgress((progress) => {
    //     console.log(`📼 ffmpeg: progress`, progress);
    // });
    await ffmpeg.load();
}

class QueueItem{
    constructor(webmBlob, clipDuration, players, space, experience){
        this.webmBlob = webmBlob;
        this.clipDuration = clipDuration;
        this.mp4Blob = null;
        this.players = players;
        this.space = space;
        this.experience = experience;
        this.userVideoId = null;
        let d = new Date()
        this.filename = 'photobooth_' + ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + "_" + ("0" + d.getHours()).slice(-2) + "-" + ("0" + d.getMinutes()).slice(-2) + "-" + ("0" + (d.getSeconds() + 1)).slice(-2);
        this.videoId = null;
        this.thumbId = null;
        this.status = null;
        this.conversionNumber = conversionCounter++;
    }
}

async function AddVideo(webmBlob, clipDuration, players, space, experience, callback){
    let queueItem = new QueueItem(webmBlob, clipDuration, players, space, experience);
    queueItem.callback = callback;
    conversionQueue.push(queueItem);
    console.log("📼 Added new video to conversion queue", queueItem);
    
    await CreateUserMedia(queueItem);
    
    if(conversionQueue.length == 1){
        StartConversion(queueItem);
    }else{
        console.log("Conversion is queued. Waiting for video conversion to finish")
    }
}

async function StartConversion(queueItem){
    
    // Uso ffmpeg para guardar primer frame como imagen y subirla
    const thumbnail = await extractVideoThumbnail(queueItem);
    await UploadThumbnail(queueItem, thumbnail);
    

    // Crear userVideo y guardar ID
    // formData.append('space', queueItem.space.id);
    // formData.append('experience', queueItem.experience.id);
    // formData.append('video', queueItem.thumbId); // esto evita errores de key duplicada
    
    // formData.append('file', "ignore_" + queueItem.thumbId); // esto evita errores de key duplicada
    // console.log("Adding users to video", queueItem.players);
    
    // Attach Uploaded Thumbnail
    const formData = new FormData;
    formData.append('thumbnail', queueItem.thumbId);
    await axios.patch(process.env.VUE_APP_API_BASE + "/api/usermedia/" + queueItem.userVideoId, formData, 
        {
            headers: { 'Content-Type': 'multipart/form-data' }
    });
    // const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/usermedia/", formData,
    // {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // });
    // queueItem.userVideoId = res.data.doc.id
    if(location.href.includes("localhost") || location.href.includes("192.168")){
        // localhost no convierto a mp4
        UploadVideo(queueItem, "webm")
    }else if(!isConverting){
        // remote server
        convertWebMtoMP4(queueItem);
    }
}

async function CreateUserMedia(queueItem){
    console.log("Creating Empty User Media in backend")
    const formData = new FormData;
    formData.append('space', queueItem.space.id);
    formData.append('experience', queueItem.experience.id);
    formData.append('converting', true);
    queueItem.players.forEach(user => {
        formData.append('users', user.dbid);
    });

    const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/usermedia/", formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    queueItem.userVideoId = res.data.doc.id
}

async function extractVideoThumbnail(queueItem) {
    const inputFileName = 'input.webm'; // Input file name
    const outputFileName = 'output.jpg'; // Output file name
    ffmpeg.FS('writeFile', inputFileName, await fetchFile(queueItem.webmBlob));
  
    await ffmpeg.run('-i', inputFileName, '-vframes', '1', outputFileName);
  
    const thumbnailData = ffmpeg.FS('readFile', outputFileName);
    console.log("🖼️ ✅ ffmpeg finished creating thumbnail");
    
    ffmpeg.FS('unlink', inputFileName);
    ffmpeg.FS('unlink', outputFileName);
  
    return thumbnailData;
}
async function UploadThumbnail(queueItem, thumbnail){
    const formData = new FormData;
    const thumbBlob = new Blob([thumbnail.buffer], {type: 'image/jpg'})
    formData.append('file', thumbBlob, queueItem.filename + ".jpg");
    formData.append('hidden', true);

    const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/files/", formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    queueItem.thumbId = res.data.doc.id;
    console.log("🖼️ ☁️ Thumbnail upload complete", res.data.doc);
    return res.data.doc;
}

async function convertWebMtoMP4(queueItem) {
    console.log("📼 🏁 ffmpeg started conversion", queueItem)
    isConverting = true;
    const inputFileName = 'input.webm'; // Input file name
    const outputFileName = 'output.mp4'; // Output file name

    ffmpeg.FS('writeFile', inputFileName, await fetchFile(queueItem.webmBlob));

    await ffmpeg.run(
      '-t', queueItem.clipDuration.toString(),
      '-i', inputFileName, // Input video file
      '-c:v', 'h264', // H.264 video codec
      '-c:a', 'aac', // AAC audio codec
      '-preset', 'fast', // Adjust preset for faster encoding
      '-strict', 'experimental', // Use experimental codecs if needed
      outputFileName
    );

    const mp4Data = ffmpeg.FS('readFile', outputFileName);

    ffmpeg.FS('unlink', inputFileName);
    ffmpeg.FS('unlink', outputFileName);

    isConverting = false;
    queueItem.mp4Blob = new Blob([mp4Data.buffer], { type: 'video/mp4' });
    console.log("📼 ✅ ffmpeg finished conversion", queueItem)
    const remoteVideo = await UploadVideo(queueItem);
    await UpdateUserVideo(queueItem, remoteVideo);

    FinishedVideoConversionAndUpload(queueItem);
}

async function UploadVideo(queueItem, extension = "mp4"){
    const formData = new FormData;
    const blob = extension == "mp4" ? queueItem.mp4Blob : queueItem.webmBlob;
    formData.append('file', blob, queueItem.filename + "." + extension);
    formData.append('hidden', true);

    // Actualizo el creado previamente
    const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/files/", formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    console.log("📼 ☁️ Video upload complete", res.data.doc);
    return res.data.doc;
}

async function UpdateUserVideo(queueItem, remoteVideo){
    const formData = new FormData;
    formData.append('video', remoteVideo.id);
    formData.append('converting', false);

    try {
        const res = await axios.patch(process.env.VUE_APP_API_BASE + "/api/usermedia/" + queueItem.userVideoId, formData);

        // Release memory
        queueItem.webmBlob = null;
        queueItem.mp4Blob = null;
        console.log("✅✅ UserVideo update complete", res.data.doc);
        
        conversionQueue = conversionQueue.filter(x => x.conversionNumber != queueItem.conversionNumber)

        // Actualiza el estado y llama al callback con el estado y la longitud de conversionQueue
        queueItem.status = 'completed';
        if (queueItem.callback && typeof queueItem.callback === 'function') {
            queueItem.callback({
                filename: queueItem.filename,
                status: 'completed',
                queueLength: conversionQueue.length
            });
        }
    } catch(error) {
        if (queueItem.callback && typeof queueItem.callback === 'function') {
            queueItem.callback({
                filename: queueItem.filename,
                status: 'error',
                queueLength: conversionQueue.length
            });
        }
    }
}

function FinishedVideoConversionAndUpload(queueItem){
    // Filtro la queue para sacar este item
    queueItem = null;
    if(conversionQueue.length > 0){
        setTimeout(() => {
            StartConversion(conversionQueue[0])
        }, 1500);
    }
}

export default {Init, AddVideo}