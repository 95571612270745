<template>
    <div id="photobooth" class="has-text-black-bis">
        <Photobooth
        :captureMode="options.captureMode"
        :cant-disparos-por-toma="options.photosTakenPerShot"
        :delay-entre-disparos="options.photosInterval"
        :stickers="options.stickers"
        :clipDuration="options.clipDuration"
        :clipFps="options.clipFps"
        :pictureFrame="options.pictureFrame"
        :musicRecording="options.sounds.musicrecording?.url"
        :musicCreation="options.sounds.musiccreation?.url"
        :canvasWidth="options.canvasWidth"
        :canvasHeight="options.canvasHeight"
        />
    </div>
  </template>
  
  <script>
  // Esta es la experiencia photobooth pero en realidad el photobooth
  // es un componente que se puede usar en cualquier lado
  import Photobooth from "@/components/PhotoboothComponentScreen.vue";
  
  export default {
    components: {
        Photobooth
    },
    props: {
    },
    computed: {
      options() {
        return this.$store.getters["space/config"].experience.component[0];
      },
    },
    sockets:{
    }
  };
  </script>
  
  